@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');


@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), 
       local('Roboto-Regular'),
       url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2) format('woff2'),
       url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff) format('woff');
    font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2) format('woff2'),
       url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmEU9fBxc4AMP6lQ.woff) format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2) format('woff2'),  
       url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfBxc4AMP6lQ.woff) format('woff');
  font-weight: 700; 
}