@import "../../styles/variables/themeColors.scss";

.AlertNotificationCard-header {
	background-color: $background-white;
  height: 56px;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
	border-bottom: 0px solid $staq-neutral-6 !important;
	padding-bottom: 5px;
	background:linear-gradient(to right, transparent 1%, #b3b9c4 1%, #b3b9c4 99%, transparent 99%);
	background-position:bottom;
	background-size: 100% 1px;
	background-repeat: no-repeat;
}

.AlertNotificationCard-main {
	margin-bottom: 37px;
    margin-top: 12px;
    height: 120px;
    border-radius: 8px;
    border: 1px solid var(--color-neutral-neutral-60, #B3B9C4) !important;
    background: var(--color-neutral-neutral-0, #FFF) !important;
}
.AlertNotificationCard-title-container {	
	margin-top: 0px;
	margin-left: 10px;
	text-align: left;
}
.AlertNotificationCard-title {	
	color: var(--color-gray-gray-900, $staq-gray-9);
    font-family: $default-font-family;
    font-size: $medium-font-size;
    font-style: normal;
    font-weight: 500;
    line-height: normal;	
}
.AlertNotificationCard-tooltip {	
	margin-top: 5px;
	margin-left: 31px;
	text-align: left;
	width: fit-content;
}
.AlertNotificationCard-timestamp {	
	text-align: left;
    line-height: 1.5;    
    width: auto;
    padding-right: 2px; 
	color: var(--color-gray-gray-900, $staq-gray-9);
	font-family: $default-font-family;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.AlertNotificationCard-condition {	
	margin-right: 10px;
	color: #7E8894;
}
.AlertNotificationCard-value {	
	margin-right: 10px;
	color: #7E8894;
}
.AlertNotificationCard-ButtonToolbarContainer {
	background: $primary-bg !important;
	margin-left: 18px;
}
.AlertNotificationCard-ButtonToolbar {	
	justify-content: flex-end;
	margin-left: 400px !important;
	background: $primary-bg !important;
}
.AlertNotificationCard-DetailsButton {	
	margin-right: 5px; 
	cursor: pointer;	
}
.AlertNotificationCard-Condition {
	display: flex;
}

.card-body {
	display: flex;
	align-items: center;
  }
  
  .AlertNotificationCard-title {
	margin-right: auto; 
  }
  

  .AlertNotificationCard-DetailsButton .btn {
	background: none; 
	border: none ;
	padding: 0 ;
	text-decoration: underline ;
	cursor: pointer;
	color:$staq-gray-9;
	font-family: $default-font-family;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-decoration-line: underline;
  }
  .AlertNotificationCard-DetailsButton .btn:focus,
  .AlertNotificationCard-DetailsButton .btn:active {
	color: $staq-gray-9;
	text-decoration: none;
  } 


  .AlertNotificationCard-DetailsButton .btn.btn-primary {
	background: none !important;
	border: none !important;
	padding: 0;
	text-decoration: underline;
	cursor: pointer;
	border-color: white !important;
  }
  
  .AlertNotificationCard-DetailsButton	.btn.btn-primary:active, 
  .AlertNotificationCard-DetailsButton	.btn.btn-primary:focus {
	color: $staq-gray-9  !important;
	text-decoration: none  !important;
	border-color: white !important;
  }

  .AlertNotificationCard-DetailsButton	.btn.btn-primary:active, 
  .AlertNotificationCard-DetailsButton	.btn.btn-primary:focus {
	outline: none !important;
  }

  .card-Detail-text {
	background: none; 
	border: none ;
	padding: 0 ;
	margin-top: 8px;
	text-decoration: underline ;
	cursor: pointer;
	color:$staq-gray-9;
	font-family: $default-font-family;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-decoration-line: underline;
  }
  a.AlertNotificationCard-DetailsButton.card-Detail-text:hover {
	color:  $staq-purple-6;
  }

  .AlertNotificationCard-ButtonToolbarContainer .btn.btn-secondary.btn.btn-primary.btn-sm {
	background: $background-white;
	border-radius: 6px;
	border: 1px solid var(--color-neutral-neutral-60, #B3B9C4);
	color: var(--Text---Primary, $staq-gray-9);
	font-family: $default-font-family;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	display: inline-flex;
	height: 26px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	flex-shrink: 0;
  }

  .AlertNotificationCard-ButtonToolbarContainer button.btn.btn-secondary.btn.btn-primary.btn-sm:hover {
	background:	$staq-purple-6;
	color:	$text-white;
  }

  .Main-AlertNotificationCardsContainer .btn.btn-secondary.btn.btn-primary.btn-sm:hover {
	background:	$staq-purple-6;
	color:	$text-white;
  }
  
  .dropdown-menu .dropdown-item:last-child {
	color: $staq-purple-4 !important;
  }

  .card-body .AlertNotificationCard-title {
    margin-left: 30px !important;
}

.AlertNotificationCard-ButtonToolbarContainer .btn.btn-secondary.btn.btn-primary.btn-sm {
	margin-top: 3px;
	margin-left: 34px;
}

.AlertNotificationCard-Condition .card-body {
	display: grid !important;
	background: $primary-bg !important;
}
.Main-AlertNotificationCardsContainer .btn-toolbar {
    margin-right: -16px !important;
}
