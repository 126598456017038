@import "../../styles/variables/themeColors.scss";
.AlertBuilder-Container {
  max-width:800px;
  margin-top:20px;
}

.AlertBuilder-AlertConditionLabel {
  color: $text-instruction;
  margin-bottom: 10px;
}

.AlertBuilder-FrequencyButton {
  margin-right: 20px;
}

.AlertBuilder-FormInput-Invalid-Label {
  color: #dc3545;  
}

.AlertBuilder-SliderContainer {
  text-align: left;
  margin-left: 5px;  
}

.AlertBuilder-SliderMain {
  display: flex;
  align-content: space-between;
}

.AlertBuilder-SliderLeftLabel {
  margin-right: 10px;
  width: 60px;
}

.AlertBuilder-SliderRightLabel {
  margin-left: 15px;
  width: 60px;
}

#alertFrequency .slider-rangeHighlight {
  background: #696fd0;
}

#alertFrequency .slider-handle {
   background: #696fd0;
}

#alertFrequency  .slider-track {
  position: absolute;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, #D0D0D0 0%, #f9f9f9 100%);
  background-image: -o-linear-gradient(top, #D0D0D0 0%, #f9f9f9 100%);
  background-image: linear-gradient(to bottom, #D0D0D0 0%, #f9f9f9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd0d0d0', endColorstr='#fff9f9f9', GradientType=0);
  background-repeat: repeat-x;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

#alertFrequency .slider-selection {
  position: absolute;
  background-image: -webkit-linear-gradient(top, #696fd0 0%, #696fd0 100%) !important;
  background-image: -o-linear-gradient(top, #696fd0 0%, #696fd0 100%) !important;
  background-image: linear-gradient(to bottom, #696fd0 0%, #696fd0 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff498DFF', endColorstr='#ff498DFF', GradientType=0);
  background-repeat: repeat-x;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
}

.add-notification-group {
  margin-right: 0px;
  margin-bottom: 10px;
  margin-left: 1px;
  background: var(--color-purple-purple-400, $staq-purple-4);
}

.buttonSpacer {
  margin-right: 28.5px;
}

.addConditionButton {
  margin-bottom: 20px;
  float: left;
  margin-left: 0px;
  background: var(--color-purple-purple-400, $staq-purple-4)!important;

}

.emptyTrashButton {
  width: 28px;
}

.thresholdPrefix {
  position: relative;
  padding-left: 15px;
}

.thresholdPrefix > i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 50%;
  pointer-events: none;
  width: 25px;
  text-align: center;
  font-style: normal;
}

.thresholdPrefix > input {
  padding-left: 20px;
  padding-right: 0;
}


.thresholdPrefix-right > i {
  right: 0;
}

.thresholdPrefix-right-select > i {
  right: 60px;
}

.thresholdPrefix-right-input > i {
  right: 12px;
}

.thresholdPrefix-right > input {
  padding-left: 0;
  padding-right: 25px;
  text-align: right;
}

.invalidFiled{
  border: #dc3545 solid 1px;
  border-radius: 6px;
}

.invalidDefiniton{
  color: #dc3545;  
}
.form-control {
  border-radius: 8px !important;
}

.AlertBuilder-Container .card-body .col-4 {
  padding-left: 0px;
  }
.AlertBuilder-Container .card-body .btn-toolbar {
  .btn-primary{
    margin-left: -4px;
    background: #ECEDF9;
    border-color: #696fd0;
    color: #696fd0;
    font-size: 12px!important;
    font-weight:normal;
  }
  .btn-primary:hover{
   background:#696fd0;
   color: #fff;
  }
 .btn-secondary{
  background: #fff;
  color: #393939;
  border: 1px solid #b3b9c4 !important;
 }
.btn-secondary:hover{
  border-color: #696fd0 !important;
  color: #696fd0;
  background: #fff !important;
 }
}

.AlertBuilder-Container .card-body .form-check {
  margin-left: -83px !important;
  margin-right: 568px;
}


.css-yk16xz-control :hover {
  border-color: $staq-purple-4 !important;
}
