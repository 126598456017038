@import "variables/app";
@import "variables/c3jscustom";
@import "variables/common-header-bar.scss";
@import "variables/common-font-faces.scss";
@import "variables/themeColors.scss";

body {
  margin: 0;
  padding: 0;
  color: var(--Text---Primary, $staq-gray-9);
  font-family: $default-font-family; 
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
}

.Page-Layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;      
}
.Page-Main {
  display: flex;
  flex: 1;  
  .table thead th{
    color: var(--Text---Primary,  $staq-gray-9);
    font-family: $default-font-family; 
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    height: 50px;
    flex-shrink: 0;
    background: $staq-purple-th;  
    border-left: 0;
    border-right: 0;
    padding-bottom: 15px;
  }
  .table th, .table td {
    padding-top: .4rem;
    padding-bottom: .4rem;
  }

  .form-control {
    height: calc(1.5em + 0.75rem + 0px);
  }
  textarea.form-control {
    height: auto;
  }
  .title{
    line-height: 1.3;
    font-size: 16px;
    font-weight: 600;    
  }
}

.Form-ButtonsContainer {
  padding:20px;
}

.Page-IconLarge {
  color: $icon-default;
  font-size: 64px;
}
.Page-emptyAlertsMessage {
    padding-top: 100px;
}

.btn-primary {
  border-color: $staq-purple-4;
  color: $background-white;
  border-radius: 8px;
}
.add-notification-group.btn.btn-secondary:hover,
.btn-primary:hover {
  background: var(--color-purple-purple-600, $staq-purple-6);
  border-color: $staq-purple-6;
  color: #fff;
}
.btn-secondary {
  border-color: none;
  color: $background-white;
  border: none !important;
}

.btn-confirm {
  background: var(--color-purple-purple-400, $staq-purple-4);
  color: $background-white;
}

.btn-delete {
  background-color: $color-warn;
  border-color: $color-warn;
  color: $background-white;
}

.btn-cancel {
  background-color: $color-tertiary-a;
  border-color: $color-tertiary-a;
  color: $background-white;
}

.btn-menu {
  color: $text-dark;

}

.btn {
  font-size: 12px;
  line-height: 12px;
  padding: 5px 8px;
  margin: 0 8px;
  height: 24px;
}

.form-control{
  font-size: 13px;
}

.react-multi-email {
  border: 1px solid $border-dark-grey;
}

.react-multi-email.focused {
  border-color: $staq-purple-4 !important;
  box-shadow: 0 0 0 0.04rem $staq-purple-4 !important;
}

.react-multi-email:focus {
  border-color: $staq-purple-4 !important;
  box-shadow: 0 0 0 0.04rem $staq-purple-4 !important;
}

.react-multi-email.empty>span[data-placeholder]  {
  color: var(--color-gray-gray-600, $staq-gray-6);
  font-family:$default-font-family !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.react-multi-email [data-tag] {
    margin: 0.14285714em 0.14285714em !important;
    background: #fff;
    border: 1px solid #b3b9c4 !important;
}

input#groupName {
  color: $staq-gray-9 !important;
  font-family: $default-font-family !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  border-color: 1px solid $border-dark-grey !important;
}

.form-label, input#groupName {
  color: $staq-gray-9;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  font-family: $default-font-family;
  font-style: normal;
}

.form-label {
    color: $staq-gray-9;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    font-family: $default-font-family;
    font-style: normal;
}

.Page-loadingMessageContainer {
  display: flex;
  justify-content: center;
  background: $background-white !important;
  color: $staq-purple-4 !important;
}

.Page-loadingMessage {
  font-size: 16px;
  border-radius: 6px !important;
  border: 1px solid var(--Color-Neutral-Neutral-60, $staq-neutral-6);
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  align-items: center;
  background-color: $background-white !important;
  color: $staq-purple-4 !important;
  display: flex;
  height: 40px;
  justify-content: center;
  opacity: .8;
  width: 200px;
  z-index: 100000;
}


//-----added new UI reskining changes
.btn {
  margin: 0 3px;
  height: 30px;
}

.btn {
  display: inline-flex;
  padding: 7px 15px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
}
	
.btn-secondary {
  color: var(--color-base-white, $background-white);
  font-family: $default-font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
 }

 .btn-confirm {
   border-color: none;
 }

 a.btn-cancel:hover, a.btn-confirm:hover {
  background: $staq-purple-6;
  color: var(--color-base-white, $background-white);
  border-color: none;
 }

.AlertNotificationCard-ButtonToolbarContainer{
   .btn-secondary{
	background: #fff;
	color:$staq-gray-9;
	border-color:$staq-neutral-6;
    }
 .btn-secondary:hover{
	background: #fff;
	color:$staq-purple-4;
	border-color:$staq-purple-4;
    }
}

.Page-Main .table td, .Page-Main .table th {
  border-left: 0;
  border-right: 0;
  background: $staq-neutral-0;
}
.table-responsive {
  border-radius: 8px;
  border: 1px solid var(--color-neutral-neutral-60, $staq-neutral-6);
}

.table {
 margin-bottom: 0 !important;
 color: var(--Text---Primary,  $staq-gray-9) !important;
}


.card {
  border: none !important;
  background: var(--color-neutral-neutral-10, $staq-neutral-1) !important;
}
.Page-Main .table td{
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  height: 50px !important;
  vertical-align: middle !important;
}
 .Page-Main .table th {
  padding-bottom: 0.4rem;
  padding-top: 18px;
  height: 50px;
  }
button.btn.btn-secondary.addConditionButton.btn.btn-primary:hover {
  border-radius: 8px;
  background: var(--color-purple-purple-600, $staq-purple-6);
  }

  input[type="checkbox"] {
    accent-color: $staq-purple-4;
  }

  .form-check-input {
    position: absolute;
    margin-top: 0;
    margin-left: -1.25rem;
}

input[type=checkbox] {
  margin-left: 1px;
}

.css-2b097c-container {
  margin-right:13px;
}

.form-check-label {
  margin-left: 22px;
  margin-right: -34px;
}
.bs-tooltip-top {
  .arrow {
    &::before {
      border-top-color: white;
      box-shadow: 0px 7px 16px -1px rgba(0,0,0,0.33) !important;

    }
  }
}

.tooltip-inner {
  color:  #393939 !important;
  background-color:  white !important;
  box-shadow: 0px 7px 16px -1px rgba(0,0,0,0.33) !important;
  -webkit-box-shadow: 0px 7px 16px -1px rgba(0,0,0,0.33) !important;
  -moz-box-shadow: 0px 7px 16px -1px rgba(0,0,0,0.33) !important;
  
}

.btn-secondary:not(:disabled):not(.disabled):active {
  border: none !important; 
  background-color: var(--color-purple-purple-400, $staq-purple-4) !important;
}

.NotificationGroup-ActionButton i.fa.fa-trash:hover {
  color: $staq-red-2;
}

.btn-secondary.focus, .btn-secondary:focus {
  box-shadow: none !important;
}
.btn:focus {
  box-shadow: none !important;
} 

.Form-ButtonsContainer.form-row .btn-cancel:hover {
  color: $staq-purple-6;
  border-color:$staq-purple-6;
}

.react-multi-email {
  border-radius: 8px !important;
}


.form-control:focus {
  border-color: $staq-purple-4 !important;
  box-shadow: 0 0 0 0.2rem $staq-purple-4 !important;
}
.ConfirmationPopover-ButtonsContainer{
	.btn-cancel{
	     background: #fff;
	     color: $staq-gray-9;
	     border: 1px solid $staq-neutral-6;
	}
	.btn-cancel:hover{
		border-color:$staq-purple-4;
		color: $staq-purple-4;
 		background: #fff !important;
	}
	.btn-danger{
	     background: $staq-purple-4;
	     color: #fff;
	     border: 1px solid $staq-purple-4;
	}
	.btn-danger:hover{
	     border-color:$staq-purple-6;
	     border-color: $staq-purple-6;
	}
}

