@import 'themeColors';
@import 'common-font-faces';
.staq .navigation .center {
    float: left;
    height: 40px;
}
header.navigation {
    display: flex;
    justify-content: space-between;
}

.staq .navigation .center ul {
    min-width: 310px;
    list-style: none;
    padding-top: 18px;
    padding-right: 110px;
    font-size: 14px;
    font-family: $default-font-family;
}


.staq .navigation .right>div {
    border-left: solid 0px $staq-gray-0;
    display: inline;
    float: left
}

.staq .navigation .center a .selected {
    height: 28px;
    border-bottom: solid 4px $staq-purple-4;
    color: $staq-purple-4;
    font-weight: bold;
    font-size: 14px;
    font-family: $default-font-family;
}

.staq .navigation .center a {
    color: $input-color;
    display: inline-block;
    font-size: 14px;
    padding: 0px 7px;
    font-family: $default-font-family;

    > em {
        border-radius: 3px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        background: $staq-purple-4;
        color: $primary-bg;
        font-size: 9px;
        font-style: normal;
        font-family: $default-font-family;
        padding: 1px 5px 0 5px;
        position: relative;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        margin-left: 3px;
        display: none;
      }
}

.staq .over {
    opacity: 0;
    z-index: 99999 !important;
}

.staq .over .over-container {
    background-color: white;
    border: solid 1px $input-grey-light;
    min-height: 45px;
    min-width: 45px;
    max-width: 500px;
    position: relative;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px
}

.staq .over .over-container .over-body {
    padding: 15px;
    background-color: $primary-bg;
    border-radius: 8px;
}



.staq .over .over-container .over-footer {
    background-color: $secondary-bg;
    padding: 10px;
    text-align: center
}

.staq .navigation .right .main-menu span.display-name {
    padding: 15px 0;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    margin-bottom: 4px;
    color: $staq-gray-9;
    background-color: $staq-neutral-0;    
}

.staq .navigation .right .main-menu a.menu-caret {
    color: $staq-neutral-8;
    display: inline-block;
    font-size: 14px;
    font-family: $default-font-family;
    text-align: center;
    width: 0px;
    opacity: 0
}

.staq .navigation .center ul li {
    display: inline
}

.staq .navigation .right .main-menu .over .over-container {
    background-color: $panel-dark-primary;
    border: 1px solid $panel-dark-primary;
}


.staq .navigation .right .main-menu nav.menu {
    display: none;
    min-width: 231px;
    text-align: left;
    background-color: $staq-neutral-0
}

.staq .navigation .right .main-menu nav.menu li {
    margin-bottom: 5px
}
.staq .navigation .right .main-menu nav.menu li:last-child {
    margin-bottom: 0px
}
.staq .navigation .right .main-menu nav.menu li.seperated {
    border-top: 1px solid $staq-neutral-8;
    padding-top: 12px;
    margin-top: 12px
}


.staq .navigation .right .main-menu nav.menu a,
.staq .navigation .right .main-menu nav.menu button {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    color: $staq-gray-9;
    display: block;
    font-size: 14px;
    font-family: $default-font-family;
    font-weight: 400;
    line-height: 250%;
    text-indent: 21px
  
}

.staq .navigation .right .main-menu nav.menu a:hover,
.staq .navigation .right .main-menu nav.menu button:hover {
    color: $staq-purple-6;
    background-color: $staq-neutral-2;
    border-radius: 6px
}

.main-menu .over .over-container {
   border: none  !important;
   box-shadow: 7px 2px 10px 3px rgba(56,36,36,0.75);
   -webkit-box-shadow: 7px 2px 10px 3px rgba(56,36,36,0.75);
   -moz-box-shadow: 7px 2px 10px 3px rgba(56,36,36,0.75);
 
}



.menu ul li:last-child >a{
    color: $staq-purple-4 !important;
}

.main-menu .over{
 z-index: 99999 !important;
 left: 1617px !important;
}

.staq .notifications-dropdown * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
   
}

.staq .notifications-dropdown>a {
    color: $staq-neutral-9;
    display: inline-block;
    font-size: 18px;
    padding: 16px 20px 14px;
    position: relative
}

.staq .notifications-dropdown>a em {
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    background: $staq-purple-4;
    color: white;
    font-size: 9px;
    font-style: normal;
    height: 15px;
    left: 50%;
    line-height: 1;
    min-width: 15px;
    padding: 3px 4px 0 3px;
    position: absolute;
    text-align: center;
    top: 6px;
    white-space: nowrap;
    vertical-align: baseline
}

.staq .notifications-dropdown .notifications-menu {
    display: none;
    width: 349px;
}

.staq .notifications-dropdown .notifications-menu section.notifications-feed .notifications .no-results {
    font-size: 24px;
    font-family: $default-font-family;
    color: $staq-purple-4;
    padding: 25px;
    text-align: center;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}


.staq .notifications-dropdown .notifications-menu section.notifications-feed .notifications {
    height: 399px;
    overflow-x: hidden;
    overflow-y: scroll
}

.staq .notifications-dropdown .notifications-menu section.notifications-feed .notifications article .message { 
    height: 40px;
    padding-left: 27px;
    padding-right: 0
}
.staq .notifications-dropdown .notifications-menu footer {
    border-top: 1px solid $input-grey-light;
}

.staq .notifications-dropdown .notifications-menu footer input[type='submit'],
.staq .notifications-dropdown .notifications-menu footer a {
    background-color: $background-grey-light;
    color: $text-dark;
    display: block;
    font-size: 14px;
    font-family: $default-font-family;
    height: 47px;
    padding: 14px;
    text-align: center;
    width: 100%
}

.staq .notifications-dropdown .notifications-menu footer input[type='submit']:hover,
.staq .notifications-dropdown .notifications-menu footer a:hover {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    color: $staq-purple-4;
    background-color: $staq-purple-0;
    font-size: 14px;
    font-family: $default-font-family;
    font-weight: bold;
    border-color: $staq-purple-4;
}

.staq .notifications-dropdown .notifications-menu section.notifications-feed .notifications article .title .icon span:not([class^="ft-attention"]) {
    color: $staq-neutral-8;
}

.staq .notifications-dropdown .notifications-menu section.notifications-feed .notifications article .title .icon span[class^="ft-attention"] {
    color: $staq-orange-2;
 }

 .staq .navigation .right .help-button a {
    color: $staq-gray-9;
    font-family: $default-font-family;
    font-size: 12px;
    font-style: normal;
    display: inline-block;
    padding: 15px 20px
 }
 
 .staq .navigation .right .help-button a:hover {
     color: $text-dark;
 }
 
 .staq .navigation .right .help-button a span {
     color: $help;
 }

 //box shadow -reports
.staq .navigation{
    box-shadow: 0px 1px 6px 0px rgba(223, 226, 230, 0.60) !important;
 }
 //box shadow -connection
 .staq .title-row {
    box-shadow: inset 0 5px 5px -5px rgba(0, 0, 0, 0.25);
 }
 //box shadow -sources
 .staq .staq-source-manager .wrap {
    box-shadow: inset 0 5px 5px -5px rgba(0, 0, 0, 0.25);
 }
  //box shadow -dashboard and innter reports
 .staq .table-toolbar {
     box-shadow: inset 0 5px 5px -5px rgba(0, 0, 0, 0.25);
 }

