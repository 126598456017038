@import "../../styles/variables/themeColors.scss";


.NotificationGroup-Main {
  margin-top: 20px;
}

.NotificationGroup-ButtonToolbar {
   color: $staq-neutral-16;
  padding-top: 3px;
}

.NotificationGroup-ActionButton {
  padding: 5px;
  cursor: pointer;
}
.NotificationGroup-ActionButton:hover {
  color: #3e44b2 !important;
}
.NotificationGroup-EditIcon:hover{
  color: $staq-purple-4 !important;
}
.NotificationGroup-EditIcon {
  margin-right: 5px;
  color: $staq-neutral-16;
}
