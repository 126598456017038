@import "../../../styles/variables/themeColors.scss";

.MultiSelect {
  
  .optionsContainer {
    border: 1px solid $border-side-panel;
    border-radius: 4px;
    background: $background-white;    
    height: 125px;
    overflow: auto;

    .optionsList {      
      border-radius: 4px;
      overflow-y: auto;
      height: 300px;
      
      .optionMain {
        display: flex;        

        &:hover {
          background: $panel-light-primary;
          cursor: pointer;
        }

        .optionToggleContainer {
          padding: 5px;
          height: 20px;
        }

        .optionLabel {
          color: $text-dark;
          font-size: 13px;
          padding-top: 5px;          
        }

      }
    }    
  }
}
