@import "../../styles/variables/app.scss";

.Menu-RightButton {
  text-align: right;
  height: 100%;
  padding-top: 10px;
  margin-right: 15px;
}
.underline-nav{
  white-space: nowrap;
}
.Menu-Navbar {
  min-width: 155px;
  max-width: 155px;
  line-height: 1.3;
  font-size: $title-font-size;
  font-weight: 600;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;  
}
.Menu-divisor {
  width: 100%;
  color: $border-grey;
  margin: 0px;
  border: 1px solid $staq-neutral-6;
}

@media only screen and (max-width: $smallScreen) {
  .App {
    padding-bottom: 85px;
  }

  .mobile-nav {
    position: fixed;
    height: 60px;
    background: $background-white;
    z-index: 999999;
    padding-top: 10px;
    text-align: center;
    overflow-y: auto;
    -webkit-box-shadow: 0px 0px 11px rgba(51, 51, 51, 0.13);
    -moz-box-shadow: 0px 0px 11px rgba(51, 51, 51, 0.13);
    box-shadow: 0px 0px 11px rgba(51, 51, 51, 0.13);
    ul li {
      :hover {
        color: $panel-dark-secondary;
      }
      div.active {
        i {
          color: $panel-dark-tertiary;
        }
      }
    }
    ul {
      font-size: $input-font-size;
      margin-bottom: 0px;
      padding-left: 0px;
      list-style-type: none;
      display: -ms-flex;
      display: flex;
      justify-content: space-around;
    }
  }
}
