@import "../../../styles/variables/themeColors.scss";

.AlertChip-MetricRow {
  border-bottom: 1px solid $hr-on-light;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 14px;
  padding: 5px;
  margin-bottom: 5px;
  text-align: left;
  color: $staq-gray-9;
  font-family: $default-font-family;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.AlertChip-Badge {
  width: auto;
  cursor: pointer;
  margin-left: 12px;
  min-width: 35px;
}

.AlertChip-Panel {
  display: flex;
  color: $staq-neutral-16;
}

AlertChip-Panel:hover {
  color: $staq-purple-6;
}

.AlertChip-EditAction {
  width: 20px;
  cursor: pointer;
}

.BrokenAlertChip-MetricRow {
  font-size: 12px;
  color: #dc3545;
  border-bottom: 1px solid $hr-on-light;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 14px;
  padding: 5px;
  margin-bottom: 5px;
  text-align: left;
}
