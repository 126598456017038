//staq new color palette
// purple colors
$staq-purple-th:#f4f5f9;
$staq-purple-0:#ecedf9;
$staq-purple-1:#c5c7ed;
$staq-purple-2:#a9ace4;
$staq-purple-3:#8286d7;
$staq-purple-4:#696fd0;
$staq-purple-5:#444bc4;
$staq-purple-6:#3e44b2;
$staq-purple-7:#30358b;
$staq-purple-8:#25296c;
$staq-purple-9:#1d2052;

//  Neutral Colors
$staq-neutral-0:#ffffff;
$staq-neutral-1:#fafbfb;
$staq-neutral-2:#f5f6f7;
$staq-neutral-3:#ebedf0;
$staq-neutral-4:#dfe2e6;
$staq-neutral-5:#c2c7d0;
$staq-neutral-6:#b3b9c4;
$staq-neutral-7:#a6aebb;
$staq-neutral-8:#98a1b0;
$staq-neutral-9:#8993a4;
$staq-neutral-10:#7a8699;
$staq-neutral-11:#6b788e;
$staq-neutral-12:#5d6b82;
$staq-neutral-13:#505f79;
$staq-neutral-14:#42526d;
$staq-neutral-15:#354764;
$staq-neutral-16:#243757;
$staq-neutral-17:#15294b;
$staq-neutral-18:#091e42;

// Gray Colors
$staq-gray-0: #f3f3f3;
$staq-gray-1: #dadada;
$staq-gray-2: #c8c8c8;
$staq-gray-3: #afafaf;
$staq-gray-4: #9f9f9f;
$staq-gray-5: #878787;
$staq-gray-6: #7b7b7b;
$staq-gray-7: #606060;
$staq-gray-8: #4a4a4a;
$staq-gray-9: #393939;

// Red Colors
$staq-red-0: #fbe8ea;
$staq-red-1: #f4b8be;
$staq-red-2: #db1a2d;

// Orange Colors
$staq-orange-0: #fff4e6;
$staq-orange-1: #fedcb0;
$staq-orange-2: #fb8d00;

// Yellow Colors
$staq-yellow-0: #fffbe7;
$staq-yellow-1: #fff1b4;
$staq-yellow-2: #ffd20c;

// Green Colors
$staq-green-0: #eaf6eb;
$staq-green-1: #bfe2c1;
$staq-green-2: #30a237;



//Primitive Colors
$color-primary: $staq-purple-4; //new
$color-secondary: #7E8894; //new
$color-tertiary-a: #DCDFE0; //new
$color-help: #7E8894; //same as color-secondary
$color-confirm: #00AA4E; //new
$color-warn: #E34332; //new
$input-color: #2b3134;
$primary-bg: #fff;
$input-grey-light: #CCCCCC; //new
$secondary-bg: #f8f8f8;
$panel-dark-primary: #1E292F; //new
$help: #7e8894;


// Text colors
$text-default: #9099a3;
$text-dark: #2b3134;
$text-instruction: #5F6872;
$text-white: #ffffff;

// Background Colors
$background-white: #ffffff;
$background-grey-light: #f8f8f8;
$panel-light-primary: #f2f2f2;
$background-grey-dark: #141c1f;

// Border colors
$border-side-panel: #c1c1c1;
$border-grey: #bcbcbc;
$border-light-grey: #e5e5e5;
$border-dark-grey: #ced4da;
$hr-on-light: #cbcbcb;

// Incon Colors
$icon-default: #616a74;
$icon-light: $text-default;
$icon-blue:	$color-primary;


$input-font-size: 12px;
$title-font-size: 16px;
$medium-font-size: 14px;

// Fill Colors
$panel-dark-secondary: #3D5360;
$panel-dark-tertiary: #51788C;


$default-font-family: Roboto;
$secondary-font-family: 'Open Sans';
$awesom-font-family: Font Awesome 5 Pro;
$fontello-font-family: fontello;