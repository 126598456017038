@import "../../styles/variables/app.scss";
@import "../../styles/variables/themeColors.scss";

.AlertsTable-ToggleContainer {
  text-align: left;
}

.AlertsTable-ToggleOn {
  color: $staq-purple-4;
  padding: 5px;
  cursor: pointer;
  font-size: 16px;  
  transform: scale(1.3);
}

.AlertsTable-ToggleOff {
  color: $text-default;
  padding: 5px;
  cursor: pointer;
  font-size: 16px; 
  transform: scale(1.3);
}

.AlertsTable-ReportLink {
  color: $staq-gray-9;
  text-decoration: underline;
}

.AlertsTable-ReportLink:hover{
  color: #696FD0 !important;
}

.AlertsTable-ReportIcon {
  margin-left: 5px;
  display:none;
}

.AlertsTable-Badge {
  margin-left: 5px;
  cursor: pointer;
}

.AlertsTable-ButtonGroup {
  color: $staq-neutral-16;
  padding-bottom: 3px;
}

.AlertsTable-ButtonGroup-Broken {
  color: #dc3545;
}

.AlertsTable-ActionButton {
  padding: 5px;
  cursor: pointer;
  margin-top: 5px;
  margin-left: 4px;
}

.AlertsTable-AlertLink {
  color: $text-dark;
  text-decoration: none;
}

/* Small screens */
@media all and (max-width: $smallScreen) {
  .navigation {
    display: none;
  }
  .spacedCell {
    padding: 1px;
    padding-top: 1px;
    padding-left: 5px;
    text-align: left;
  }
  .borderedBlock {
    border: solid 1px $border-light-grey;
  }
  .AlertsTable-Badge {
    font-size: 14px;
  }
  .AlertsTable-ToggleOn {
    color: $staq-purple-4;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
    font-size: 28px;
    div {
      font-size: 14px;
      color: $text-default;
      padding: 5px;
      float: right;
      padding-top: 11px;
    }  
  }
  .AlertsTable-ToggleOff {
    color: $text-default;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
    font-size: 28px;
    min-width: 120px;
    div {
      font-size: 14px;
      color: $text-default;
      padding: 5px;
      float: right;
      padding-top: 11px;
    }  
  }
  .AlertsTable-ToggleContainer {
    text-align: left;    
  }
  .AlertsTable-ToggleMain {
    padding: 5px;
    padding-top: 0px;
    min-width: 135px
  }
  .AlertsTable-ActionsBlockContainer {
    text-align: right;
    padding-right: 0px;
  }
  .AlertsTable-ReportLink {
    font-weight: 600;
    padding-top: 4px; 
  }
  .AlertsTable-Condition {
    color: $text-default;
    display: flex;
  }
  .AlertsTable-ReportLink:hover{
    color: #696FD0 !important;
  }

}

.AlertsTable-ActionButton .fa-pencil:hover {
    color: $staq-purple-4;
}


.AlertsTable-ActionButton .fa-trash:hover {
  color: $staq-red-2;
}
