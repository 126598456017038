@import "../../styles/variables/themeColors.scss";

.AlertsSidePanel-SidebarContainer {
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
  width: 218px;
  background: $background-white !important;
  border: 0.5px $staq-neutral-6;
  margin-right: 16px;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
}
.AlertsSidePanel-SidebarContainer .scrollable {
  max-height: 767px;
  width: 197px;
  overflow: scroll;
  overflow-x:hidden;
  overflow-y: auto;
}

.AlertsSidePanel-Arrow {
    margin-left: -4px;
    color: $staq-neutral-16;
}

.AlertsSidePanel-Arrow:hover {
	margin-left: -4px;
  color: $staq-purple-6;
}

.AlertsSidePanel-NoAlertLabel {
	text-align: center;
	margin-top: 10px;
}

.AlertsSidePanel-TitleContainer {
	margin-bottom: 10px;
}

.AlertsSidePanel-Title {
	text-align: left;
	margin-left: 8px;
	font-size: 14px;
	color: $text-dark;
}

.AlertsSidePanel-Button {
	text-align: right;
}

.AlertsSidePanel-ExtraItemsButtonContainer {
	text-align: center;
	margin-top: 10px;
}
.AlertsSidePanel-ExtraItemsButton {
	color: $text-dark;
	text-decoration: none;
  box-shadow: none !important;
}

// reskining changes

.AlertsSidePanel-SidebarContainer .scrollable {
  max-height: 767px;
  width: 197px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: 0px;
  background: $background-white;
}

.AlertsSidePanel-Title {
    text-align: left;
    margin-top: 13px;
    margin-left: 20px;
    color: #000;
    font-family: $default-font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.row {
  margin-left: 0;
  margin-right: 0;
}

.AlertsSidePanel-Title {
  text-align: left;
  margin-top: 6px;
  margin-left: 5px;
  color: #000;
  font-family: $default-font-family;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.AlertsSidePanel-Button .btn {
display: inline-flex;
height: 20px;
padding: 7px 20px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 6px;
border: 1px solid var(--color-neutral-neutral-60, $staq-neutral-6);
color: var(--Text---Primary, $staq-gray-9);
font-family: $default-font-family;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin:0;
background: $staq-neutral-0;
margin-right: 6px;
margin-top: 5px;
}

.Page-emptyAlertsMessage .btn-secondary:hover,
.AlertsSidePanel-Button .btn:hover {
  border-color: $staq-purple-6;
  color: $staq-purple-6;
}

.AlertChip-EditAction {
  width: 4px;
}

button.AlertsSidePanel-ExtraItemsButton.btn.btn-link {
  background: $background-white;
  color: var(--color-gray-gray-900, $staq-gray-9);
  font-family: $default-font-family;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.AlertsSidePanel-ExtraItemsButtonContainer {
  background: $background-white;
}
.AlertsSidePanel-SidebarContainer.container .col {
  background: $background-white;
}
.AlertsSidePanel-SidebarContainer.container .row {
  background: $background-white;
}



