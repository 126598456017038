@import "../../styles/variables/themeColors.scss";
@import "../../styles/variables/common-header-bar.scss";
@import "../../styles/variables/common-font-faces.scss";

Nav .justify-content-end .nav-item {
  border:none;
}

.navbar {
  padding: 0px;
  display: block;
  font-family: "Open Sans";
  background-color: $staq-neutral-1;
}

// new ui -reskining changes added
.navbar .navbar-brand {
  float: left;
  height: 36px;
  padding: 10px 0 10px 15px;
  display: block;
  line-height: 20px;
  margin: 0px;
  font-family: $default-font-family;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $staq-gray-9;
}

.navbar-light {
  .dropdown.nav-item {
    padding-top: 0px !important;
  }
}

.navbar .navbar-brand img {
  width: 114px;
}

Nav .mr-auto {
  color: $staq-gray-9;
  text-align: center;
  font-family: $default-font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 315px !important;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--color-gray-gray-900, $staq-gray-9);
  text-align: center;
  font-family: $default-font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.navbar-nav .nav-link {
  padding-top: .9rem;
}

Nav .nav-item {
  font-size: 14px;
}

Nav .Header .active {
  height: 49px;
  border-bottom: solid 3px $staq-purple-4;
}

Nav .notifications .btn {
  padding: 0px;
}

Nav .notifications .badge {
  position: absolute;
  left: 50%;
  top: 6px;
}

.badge-pill {
  border-radius: 3px;
  padding: 3px 4px 0 4px;
  font-size: 9px;
  height: 15px;
}

Nav .notifications .fa {
  color: grey;
}

Nav .notifications .nav-items {
  display: inline;
}

Nav .support {
  min-width: fit-content;
  padding: 0px 5px;
  color: #2b3134;
  display: inline;
  float: left;
}

Nav .support a {
  padding: 15px 20px !important;
}

Nav .support .fa {
  color: grey;
  opacity: 0;
}

.notify-footer {
  border-top: 1px solid #e2e2e2;
  position: relative;
}

.notify-footer p {
  color: green;
  margin-top: 1em;
}

.notify-footer .btn-outline-secondary {
  background-color: #f8f8f8;
  border: none;
}

.notify-footer .btn-outline-secondary:hover {
  background-color: lightgrey;
  color: grey;
}

.dropdown .dropdown-menu {
  color: $staq-neutral-8;
  display: inline-block;
  font-size: 14px;
  font-family: $default-font-family;
  width: 0px;
  opacity: 0;
  z-index: 0;
}

.navbar-expand-md .navbar-nav .dropdown.show .dropdown-menu.show {
  margin-right: 0px !important;
}

.dropdown.show .dropdown-menu.show {
  display: block;
  visibility: visible;
  opacity: 1;
  z-index: 1;
  background-color: white;
  display: block;
  visibility: visible;
  border-radius: 8px;
  width: 260px;
  margin-top: 3px;
  margin-right: 21px;
  border: none  !important;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.5) !important;
}

.dropdown-menu {
  min-width: 212px !important;
  border-radius: 0;
  margin-right: 5px;
  background-color: #1e292f;
  border: 1px solid #1e292f;
  max-height: 360px;
  padding-top: 10px;
  display: none;
  min-width: 231px;
  text-align: left;
  background-color: $staq-neutral-0
}

 .dropdown-item  {
    transition: all .5s ease-in-out;
    color: $staq-gray-9;
    display: block;
    font-size: 14px;
    font-family: $default-font-family;
    font-weight: 400;
    line-height: 250%;
    border-radius: 6px;
    width: 235px;
    margin-left: 10px;
}

.dropdown-item:active {
  color: $staq-purple-6 !important;
  background-color: $staq-neutral-2;
  border-radius: 6px;
  width: 230px;
}

.dropdown-item:hover {
  color: $staq-purple-6 !important;
}


.dropdown-item ul li:last-child >a{
  color: $staq-purple-4 !important;
}

.dropdown-divider {
  border-top: 1px solid #949B9E;
  margin: 6px 14px;
}

.notificationDropdown.dropdown-menu {
  background-color: white;
  border: solid 1px #cccccc;
  padding-bottom: 0px;
}

.notificationDropdown .dropdown-item {
  border-bottom: 1px solid $border-light-grey;
  font-size: 12px;
  color: #424b50;
  padding: 12px;
  font-weight: 400;
}

.notificationDropdown .dropdown-item:hover {
  color: #424b50;
  background-color: $staq-neutral-2;
}

.notificationSub {
  font-size: 12px;
  font-weight: 300;
  color: #9099a3;
}

.notificationBell {
  padding: 11px 23px 14px;
  display: inline-block;
  position: relative;
}

.notificationBell img{
  height: 20px;
  width: 17px;
}

.dropdown.nav-item {
  padding: 0 16px;
}

.Header {
  background-color: white;
  border-bottom: 1px solid $border-light-grey;
  cursor: auto;
  min-height: 49px;
  width: 100%;
  min-width: 380px;
  display: block;  
}

.navbar-toggler {
  float: right;
  margin-top: 4px;
  margin-right: 4px;
}

.dropdown.nav-item {
  height: 50px;
}

.navbar-nav .dropdown-menu {
  position: absolute;
}



.dropdown-toggle::after {
  content: "";
  border: none;
}


.dropdown-menu .menu-caret {
  color: $staq-neutral-8;
  display: inline-block;
  font-size: 14px;
  font-family: $default-font-family;
  text-align: center;
  width: 0px;
  opacity: 0
}
//--- new UI reskining changes
#basic-navbar-nav .navbar-nav .nav-link.active:last-child {
  height: 50px;
  border-bottom: solid 4px $staq-purple-4;
  color: $staq-purple-4;
  font-weight: bold;
  font-size: 14px;
  font-family: $default-font-family;
  .btn-primary.badge{
    border-radius: 10px !important;
  }
}

.navbar-expand-lg .navbar-nav .nav-link.active {
  height: 50px;
  border-bottom: solid 4px $staq-purple-4;
  color: $staq-purple-4;
  font-weight: bold;
  font-size: 14px;
  font-family: $default-font-family;
}

.navbar-expand-lg .navbar-nav {
  margin-top: 5px;
  padding-left: 99px !important;
}

.dropdown.nav-item {
  padding: 3px 0px !important;
}


.mr-auto, .mx-auto {
  margin-right: 0;
}

.justify-content-end {
  margin-left: 16px !important;
}

.main-menu .over{
  z-index: 99999 !important;
 }

 .Reskin-ToggleOn {
  color: $staq-purple-4;
  padding: 5px;
  cursor: pointer;
  font-size: 16px;
  transform: scale(1.3);
  margin-top: 11px;
  margin-left: -15px;
}

.Reskin-ToggleOff {
  color: $text-default;
  padding: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 11px;
  margin-left: -15px;
}

Nav .themeText {
  min-width: fit-content;
  padding: 0px 5px;
  color: #2b3134;
  display: inline;
  float: left;
}

Nav .themeText a {
  padding: 15px 20px !important;
}

Nav .themeText .fa {
  color: grey;
  opacity: 0;
}
.navbar-light .navbar-nav .dropdown .dropdown-toggle:after{
    content: ">" !important;
    border: none;
    font-family: "Consolas", "Courier", monospace;
    font-size: 19px;
    vertical-align: baseline;
    font-weight: 900;
    transform: rotate(90deg);
}

.navbar-light .navbar-nav .dropdown.show .dropdown-toggle:after{
    content: ">"!important;
    border: none;
    font-family: "Consolas", "Courier", monospace;
    font-size: 19px;
    vertical-align: baseline;
    font-weight: 900;
    transform: rotate(270deg);
}
