@import "themeColors.scss";
@import "common-header-bar.scss";
@import "common-font-faces.scss";

// Screen Sizes
$smallScreen: 767px;
//added new UI-reskining changes
.col {
    padding-left: 0px;
    padding-right: 0px;
}

.navbar-expand-md .navbar-nav {
    background: var(--color-neutral-neutral-0, $staq-neutral-0);
}

.row {
    background: var(--color-neutral-neutral-10, $staq-neutral-1)
}

.navbar {
    background: var(--color-neutral-neutral-10, $staq-neutral-1)
}

.mr-auto, .mx-auto {
    margin-right: 0;
}

.Form-ButtonsContainer.form-row {
    margin-left: 518px;
}

.Form-ButtonsContainer.form-row .btn-cancel {
    border: 1px solid var(--color-neutral-neutral-60,#b3b9c4);
    color: var(--Text---Primary,#393939);
    background: #fff;
}

.Form-ButtonsContainer.form-row .btn-cancel :hover {
    color: $staq-purple-6;
    border-color:$staq-purple-6;
}

Form-ButtonsContainer.form-row .btn.btn-confirm {
    margin-right: 31px
}

h5.AlertNotificationCard-header.card-header .btn-toolbar .fa.fa-bell {
    color: $staq-neutral-16 !important;
}

.AlertChip-Panel .fa.fa-pencil:hover {
    color: $staq-purple-6 !important;
}

button.AlertsSidePanel-ExtraItemsButton.btn.btn-link{
    text-decoration: none !important;
    border: none  !important;
}
button.AlertsSidePanel-ExtraItemsButton.btn.btn-link:hover {
    text-decoration: none !important;
    color: $staq-purple-6 !important;
    border: none  !important;
}

.bs-tooltip-top {
    .arrow {
      &::before {
        border-top-color: white;
        box-shadow: 0px 7px 16px -1px rgba(0,0,0,0.33) !important;
  
      }
    }
  }
  
  .tooltip-inner {
    color:  #393939 !important;
    background-color:  white !important;
     box-shadow: 0px 7px 16px -1px rgba(0,0,0,0.33) !important;
    -webkit-box-shadow: 0px 7px 16px -1px rgba(0,0,0,0.33) !important;
    -moz-box-shadow: 0px 7px 16px -1px rgba(0,0,0,0.33) !important;
    
  }

  .btn-secondary:not(:disabled):not(.disabled):active {
    border: none !important; 
    background-color: var(--color-purple-purple-400, $staq-purple-4) !important;
}

.NotificationGroup-ActionButton i.fa.fa-trash:hover {
    color: $staq-red-2;
}

.btn-secondary.focus, .btn-secondary:focus {
    box-shadow: none !important;
}

.btn:focus {
    box-shadow: none !important;
}

.react-multi-email {
    border-radius: 8px !important;
}
  
  
.form-control:focus {
    border-color: $staq-purple-4 !important;
    box-shadow: 0 0 0 0.04rem $staq-purple-4 !important;
}

.react-multi-email.focused {
    border-color: $staq-purple-4 !important;
    box-shadow: 0 0 0 0.04rem $staq-purple-4 !important;
  }
  
  .react-multi-email:focus {
    border-color: $staq-purple-4 !important;
    box-shadow: 0 0 0 0.04rem $staq-purple-4 !important;
  }

  .btn-toolbar .btn.btn-secondary.btn.btn-primary.btn-sm {
    background: var(--color-purple-purple-400, $staq-purple-4);
    color: white;
  }

  .Menu-RightButton .btn-secondary{
    background: $staq-purple-4;
    color: white;  
  }
 .Menu-RightButton .btn-secondary:hover{
    background: $staq-purple-6;
    color: white;  
  }

  .btn-primary.badge{
    background: var(--color-purple-purple-400, $staq-purple-4);
    color: white;  
  }

  .AlertsSidePanel-Button .btn {
      border : 1px solid var(--color-neutral-neutral-60,#b3b9c4) !important;
  }

  .justify-content-end {
    margin-right: 34px;
}
.AlertNotificationCard-main{
    .btn.btn-secondary.btn.btn-primary.btn-sm {
    background: #fff;
    border: 1px solid $staq-neutral-6 !important;
    color: $staq-gray-9;
  }
.btn.btn-secondary.btn.btn-primary.btn-sm:focus,
.btn.btn-secondary.btn.btn-primary.btn-sm:hover {
    background: #fff!important;
    border: 1px solid $staq-purple-4 !important;
    color: $staq-purple-4!important;
  }
}
